/* tslint:disable */
/* eslint-disable */
/* @relayHash c96be10be89863100a83be8897047583 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ModerationConfigRouteQueryVariables = {};
export type ModerationConfigRouteQueryResponse = {
    readonly settings: {
        readonly " $fragmentRefs": FragmentRefs<"ModerationConfigContainer_settings">;
    };
};
export type ModerationConfigRouteQuery = {
    readonly response: ModerationConfigRouteQueryResponse;
    readonly variables: ModerationConfigRouteQueryVariables;
};



/*
query ModerationConfigRouteQuery {
  settings {
    ...ModerationConfigContainer_settings
    id
  }
}

fragment EmailDomainConfigContainer_settings on Settings {
  protectedEmailDomains
  ...EmailDomainTableContainer_settings
}

fragment EmailDomainTableContainer_settings on Settings {
  emailDomainModeration {
    domain
    id
    newUserModeration
  }
}

fragment ExternalLinksConfigContainer_settings on Settings {
  featureFlags
}

fragment ModerationConfigContainer_settings on Settings {
  integrations {
    akismet {
      enabled
      ipBased
      key
      site
    }
    perspective {
      enabled
      endpoint
      key
      model
      threshold
      doNotStore
      sendFeedback
    }
  }
  moderation
  premodLinksEnable
  premoderateSuspectWords
  premoderateAllCommentsSites
  ...PreModerationConfigContainer_settings
  recentCommentHistory {
    enabled
    timeFrame
    triggerRejectionRate
  }
  newCommenters {
    premodEnabled
    approvedCommentsThreshold
    moderation {
      mode
      premodSites
    }
  }
  ...NewCommentersConfigContainer_settings
  ...EmailDomainConfigContainer_settings
  protectedEmailDomains
  externalProfileURL
  ...ExternalLinksConfigContainer_settings
  premoderateEmailAddress {
    tooManyPeriods {
      enabled
    }
  }
}

fragment NewCommentersConfigContainer_settings on Settings {
  multisite
}

fragment PreModerationConfigContainer_settings on Settings {
  multisite
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
    } as any), v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
    } as any), v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "ModerationConfigRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ModerationConfigContainer_settings"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "ModerationConfigRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExternalIntegrations",
                            "kind": "LinkedField",
                            "name": "integrations",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AkismetExternalIntegration",
                                    "kind": "LinkedField",
                                    "name": "akismet",
                                    "plural": false,
                                    "selections": [
                                        (v0 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "ipBased",
                                            "storageKey": null
                                        },
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "site",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PerspectiveExternalIntegration",
                                    "kind": "LinkedField",
                                    "name": "perspective",
                                    "plural": false,
                                    "selections": [
                                        (v0 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "endpoint",
                                            "storageKey": null
                                        },
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "model",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "threshold",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "doNotStore",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "sendFeedback",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "moderation",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "premodLinksEnable",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "premoderateSuspectWords",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "premoderateAllCommentsSites",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "multisite",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "RecentCommentHistoryConfiguration",
                            "kind": "LinkedField",
                            "name": "recentCommentHistory",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "timeFrame",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "triggerRejectionRate",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "NewCommentersConfiguration",
                            "kind": "LinkedField",
                            "name": "newCommenters",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "premodEnabled",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "approvedCommentsThreshold",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "NewCommentersModerationConfig",
                                    "kind": "LinkedField",
                                    "name": "moderation",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "mode",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "premodSites",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "protectedEmailDomains",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EmailDomain",
                            "kind": "LinkedField",
                            "name": "emailDomainModeration",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "domain",
                                    "storageKey": null
                                },
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "newUserModeration",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "externalProfileURL",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "featureFlags",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PremoderateEmailAddressConfiguration",
                            "kind": "LinkedField",
                            "name": "premoderateEmailAddress",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TooManyPeriodsConfig",
                                    "kind": "LinkedField",
                                    "name": "tooManyPeriods",
                                    "plural": false,
                                    "selections": [
                                        (v0 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "c96be10be89863100a83be8897047583",
            "metadata": {},
            "name": "ModerationConfigRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '75bc9a22f366f8ac35f85222f79b3c1a';
export default node;
